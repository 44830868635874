.addDonor-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}
.addDonor-container-left,.addDonor-container-right{
    width:100%;
}

/* .add-donor-btn button{
    margin-top: 20px;
    width: 50%;
    height: 35px;
    box-sizing: border-box;
    background-image: linear-gradient(to left,#5BA4ED,#B534AF);
    border: none;
    border-radius: 20px;
    font-size: 15px;
    padding: 0.3rem;
    color: white;

} */
.searchWrapper{
    border-radius: 25px !important;
    padding-left: 15px !important;
}