
.file_upload_contianer>div{
    display: flex;
    gap: 5px;

}
.upload_file_btn{
    min-height: 38px;
    min-width: 100px;
}

.aitem_file_btn {
    border: 1px solid #d3d3d3;
    width: 220px;
    border-radius: 20px;
    color: black;
    font-weight: 600;
    font-size: 0.9rem;
}

.aitem_file_btn::file-selector-button {
    /* background-image: linear-gradient(to left,#5BA4ED,#B534AF); */
    background-color: var(--primary-color);
    outline: none;
    border: none;
    /* color: white; */
    color: var(--shady-white);
    padding: 10px 10px;
}

.css-1p2ixqw-MuiCircularProgress-root{
    margin: 5px 34px;
}