.t_p_main {
    display: flex;
    flex-direction: column;
    gap: 25px;
    text-align: justify;
}
.terms_heading {
    text-align: center;
    margin-bottom: 20px;
}

.t_p_main > h1 {
    font-weight: 800;
}
.t_p_main > .section {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.t_p_main > .section > h1 {
    font-size: 25px;
    font-weight: 800;
}
.t_p_main > .section > ol,
.t_p_main > .section > .lists {
    padding: 0;
    padding-left: 17px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.t_p_main > .section > .lists > .lists {
    padding: 0;
    padding-left: 17px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.t_p_main > .text > b,
.t_p_main > .section > .text > b,
.t_p_main > .section > .lists > .lists > li > b {
    font-weight: 600;
}
.sub_section {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.sub_section > h3 {
    font-weight: 600;
}

@media screen and (min-width: 768px) {
    .t_p_main {
        margin: 50px auto !important;
        /* width: 85vw; */
    }
}