.signup-container {
    display: flex;
    min-height: 70vh;
}

.signup-image {
    width: 40%;
    min-height: 100%;
    background-color: rgb(137, 134, 137);
}

.signup-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.signup-right {
    flex: 1;
    display: flex;
    min-height: 100%;
    padding: 30px 0;
}

.signup-form-container {
    width: 80%;
    margin: auto;
    text-align: center;
}

.signup-heading {
    font-size: 1.4rem;
}

.signup-two-inputs {
    display: flex;
    gap: 20px;
}

.signup-password-container {
    text-align: right;
}

.signup-submit-container {
    margin-top: 25px;
}

.signup-submit-button button{
    width: 100%;
    height: 35px;
    box-sizing: border-box;
    background-color:#FC3300;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    padding: 0.3rem;
    color: white;
    margin: 5px 0;
}
/* .signup-submit-button button:hover{
    background-image: linear-gradient(to right,#5BA4ED,#B534AF);
} */

.signup-submit-button button:disabled{
    background-color: #d3d3d3;
    background-image: none;
}

.check-container{
    display: flex;
    align-items: center;
    text-align: left;
    column-gap: 10px;
    font-size: 1rem;
    margin-bottom: 15px;
}
@media only screen and (max-width: 1040px) {
    /* .signup-form-container{
        width: 100%;
    } */
    .signup-image {
        display: none;
    }
  }

  @media screen and (max-width:616px) {
    .signup-two-inputs {
        flex-direction: column;
        gap: 0;
    }
  }