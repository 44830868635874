.event-title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}
.terms-button button{
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    padding: 10px 0;
    color: white;
    font-weight: 600;
    margin: 5px 0;
    background-image: linear-gradient(to left,#5BA4ED,#B534AF);
}

.invoice_message {
    border-radius: 30px;
}