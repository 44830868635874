.sitem-wrapper {
    position: relative;
}

.sitem-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    padding: 20px;
}

.sitem-section-head {
    font-size: 0.95rem;
    color: #656565;
}

.sitem-section-head > hr {
    margin: 0;
}

.sitem-two-inputs {
    display: flex;
    gap: 20px;
}

.sitem-submit-container {
    padding: 10px;
    width: 100%;
    border-top: 1px solid #d3d3d3;
    background-color: whitesmoke;
}

.sitem-submit-container > button {
    display: block;
    margin: auto;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    padding: 10px 40px;
    color: white;
    font-weight: 600;
    background-image: linear-gradient(to left,#5BA4ED,#B534AF);
}

.accordion {
    --bs-accordion-border-radius: 20px !important;
    --bs-accordion-btn-padding-x: 1rem !important;
    --bs-accordion-btn-padding-y: 0.55rem !important;
    --bs-accordion-border-color: #d3d3d3 !important;
    margin-bottom: 20px;
}

.accordion-item:last-of-type {
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}

.accordion-item:first-of-type {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.accordion-button {
    border-radius: 20px !important;
    font-size: 0.9rem !important;
}

  
.accordion-button:focus {
    box-shadow: none;
    outline: none;
}

.accordion-button:not(.collapsed) {
    color: black;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    background-color: white;
}

.accordion-body {
    font-size: 0.9rem !important;
    padding: 10px 10px 0 10px !important;
}

.accordion-delete-btn {
    color: white;
    background-color: red;
    outline: none;
    border: none;
    margin-bottom: 15px;
    cursor: pointer;
    border-radius: 25px;
}

.accordion-edit-btn {
    color: red;
    background-color: transparent;
    outline: none;
    margin-bottom: 15px;
    cursor: pointer;
    border-radius: 25px;
    border: 1px solid red;
    padding-inline: 10px;
    margin-left: 10px;
}

.variants_buttons_container {
    margin: 10px 0;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}