.variantUl {
    padding-left: 0 !important;
}

.variantList {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 1rem;
    /* width: 50%; */
    justify-content: space-between;
    background: whitesmoke;
    padding: 10px;
}