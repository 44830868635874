
@font-face {
    font-family: "AvenirNext";
    src: local("AvenirNext"),
        url(./fonts/avenir-next/AvenirNext-Regular.otf) format("opentype");
}

@font-face {
    font-family: "AvenirNext";
    font-weight: 600;
    src: local("AvenirNext"),
        url(./fonts/avenir-next/AvenirNext-Medium.otf) format("opentype");
}

@font-face {
    font-family: "AvenirNext";
    font-weight: 800;
    src: local("AvenirNext"),
        url(./fonts/avenir-next/AvenirNext-Bold.otf) format("opentype");
}
 
:root{
--purple:#67358B;
--background:#C3D3E4;
--gray: #6e6e6e;
--primary-color: #FC3300;
--shady-white: #F0E8E3;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
	border-radius: 1px;
	background-color: #ffffff;
}

::-webkit-scrollbar
{
	width: 8px;
    height: 8px;
	background-color: #ffffff;
}

::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
	background-color: #cacaca;
}
/* 
* {
    overscroll-behavior: none;
} */

html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
}

body{
    margin: 0px auto !important;
    padding: 0px !important;
    background: #fff !important;
    font-family: 'AvenirNext', sans-serif !important;
}

.APP {
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.APP-main-container {
    display: flex;
    height: calc(100vh - 80px);
}

.loged_banner{
    width: 100%;
    /* background: url('./images/img1.png'); */
    padding: 15px 25px;
    min-height: calc(100vh - 0px);
    max-height: calc(100vh - 0px);
    background-size: cover;
    background-position: center;
    position: relative;
}

.banner2{
    /* background: url('./images/img2.png'); */
    background-size: cover !important; 
    background-position: center !important;
}

p {
    margin: 0;
}

.padding0{
    padding: 0px !important;
}

.todays_news {
    width: 90%;
    padding: 40px 80px;
    padding-right: 30px;
    border-radius: 10px;
    position: absolute;
    left: 20px;
    right: 0;
    bottom: 60px;
    background: rgba(255, 242, 242, 0.13);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(20px);
}

.todays_news button {
    background: #20DC49;
    border-radius: 10px;
    border: none;
    font-size: 14px;
    padding: 11px 20px;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.todays_news button img{
    margin-right: 10px;
}

.todays_news p{
    margin-bottom: 0;
    color: #fff;
    margin-top: 0px;
    font-size: 17px;
    line-height: 25px;
    text-align: justify;
}

.authen_form{
    width: 100%;
    background: none;
    padding: 0px;
    position: relative;
}

.upper_form {
    width: 100%;
    background: none;
    padding: 25px 40px;
    min-height: calc(100vh - 72px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.upper_form img{
    width: 125px;
    display: block;
    margin: auto;
}

.upper_form h1 {
    font-family: 'AvenirNext', sans-serif;
    text-align: center;
    margin-bottom: 0;
    margin-top: 15px;
    font-weight: 600;
    font-size: 30px;
}

.upper_form p {
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #828282;
    cursor: pointer;
}

.upper_form button {
    background: linear-gradient(180deg, #00526B 0%, #0098B9 100%);
    color: #fff;
    border: none;
    font-size: 14px;
    padding: 14px 20px;
    font-weight: 600;
    min-width: 250px;
    margin-top: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    outline: none !important;
    text-transform: uppercase;
}

.upper_form button:disabled {
    background: #b8b8b8;
    cursor: not-allowed;
}

.bottom_data{
    width: 100%;
    background: #EAFAFF;
    padding: 25px 20px;
}

.bottom_data ul{
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom_data li {
    display: inline-block;
    margin: 0px 8px;
    font-size: 14px;
    font-weight: 600;
}

.bottom_data li:first-child{
    margin-right: 20px;
}

.bottom_data li img {
    width: 18px;
}

.input_sec{
    width: 100%;
    background: none;
    padding: 25px 0px 0px;
}

.input_sec .form-control {
    height: auto;
    font-size: 12px;
    box-shadow: none;
    outline: none !important;
    padding: 12px 17px;
    text-align: left;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 1px 2px #dcdcdc;
}

.form-group{
    margin-bottom: 25px;
}

.regform button{
    width: 100%;
}

.regform h6{
    margin-bottom: 0;
    text-align: center;
    margin-top: 20px;
    font-size: 11px;
    color: #888;
    font-weight: 500;
}

.regform h6 span {
    display: block;
    font-size: 13px;
    margin-top: 7px;
    color: #00728e;
    font-weight: 700;
    text-decoration: underline;
}

.regform h6 span a{
    color: #00728e;
    text-decoration: none;
}

.regform h5{
    text-align: center;
    font-size: 11px;
    margin-bottom: 25px;
    font-weight: 400;
    color: #888;
}

.regform h5 span{
    font-weight: 700;
    color: #006781;
    text-decoration: underline;
}

.regform h5 span a{
    color: #006781;
    text-decoration: underline;
}

a{
    text-decoration: none !important;
}

.header_sec{
    width: 100%;
    background: #fff;
    padding: 10px 0px;
    box-shadow: 0px 1px 4px 0px #0000001F;
}

.logobar{
    width: 150px;
    display: inline-block;
    cursor: pointer;
}

.logobar img{
    width: 125px;
    display: block;
    margin: 0;
}

.menubar{
    width: auto;
    display: inline-block;
}

.menubar h6 {
    margin: 0px;
    text-align: right;
    font-size: 14px;
    color: #005770;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.menubar h6 a{
    color: #005770;
}

.menubar h6 span{
    font-weight: 700;
    margin-left: 7px;
    text-decoration: underline;
    color: #00526B;
}

.devide_data{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer_sec{
    width: 100%;
    background: #EAFAFF;
    padding: 15px 0px;
}

.footer_sec ul{
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_sec li {
    display: inline-block;
    margin: 0px 8px;
    font-size: 14px;
    font-weight: 600;
}

.footer_sec li:first-child{
    margin-right: 20px;
}

.footer_sec li img {
    width: 18px;
}

main{
    min-height: calc(100vh - 130px);
    width: 100%;
}

.auction_sec{
    width: 100%;
    background: none;
    padding: 40px 0px;
}

.page_title{
    width: 100%;
    background: #fff;
    margin-bottom: 35px;
}

.page_title h3{
    text-align: center;
    font-size: 25px;
    font-weight: 800;
    font-family: 'AvenirNext', sans-serif;
    margin: 0;
}

.auction_wizard{
    width: 100%;
    background: none;
    padding: 15px 0px;
}

.about_couse{
    width: 100%;
    background: none;
    padding: 0px;
}

.block_heading{
    width: 100%;
    background: none;
    margin-bottom: 40px;
}

.block_heading h4 {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    font-family: 'AvenirNext', sans-serif;
    margin: 0;
}

.about_couse label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #111;
    text-align: left;
}

.about_couse .form-control {
    height: auto;
    box-shadow: 0px 1px 1px #dadada !important;
    padding: 13px 15px;
    font-size: 12px;
    border: 1px solid #d4d4d4;
    outline: none;
    border-radius: 3px;
}

.about_couse textarea {
    resize: none;
}

.about_couse p{
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 400;
    color: #545353;
    text-align: left;
}

.about_couse p span{
    color: #00526B;
    font-weight: 700;
    text-decoration: underline;
}


.howitwork_sec {
    width: 100%;
    background: none;
    padding: 40px 0px;
}

.work_inside {
    width: 100%;
    background: none;
    padding: 15px 0px;
}

.workcard{
    width: 100%;
    background: none;
    padding: 0px;
    position: relative;
}

.workcard img{
    width: 80px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
}

.workcard p{
    margin-bottom: 0;
    text-align: center;
    font-size: 14px;
    color: #636262;
    font-weight: 400;
}

.faqs_sec{
    width: 100%;
    background: none;
    padding: 25px 0px 40px;
}

.faqs_inside{
    width: 100%;
    background: none;
    padding: 0;
}

.faqs_inside p {
    margin-bottom: 0;
    text-align: center;
    font-size: 14px;
    color: #636262;
    font-weight: 400;
    line-height: 25px;
}

.faqs_inside button{
    background: linear-gradient(180deg, #00526B 0%, #0098B9 100%);
    display: block;
    margin: auto;
    margin-top: 40px;
    border: none;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    padding: 15px 35px;
    border-radius: 10px;
    min-width: 250px;
}

.faqs_inside h6{
    margin: 0;
    margin-top: 15px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #777;
}

.faqs_inside h6 span {
    font-weight: 700;
    color: #00617b;
    text-decoration: underline;
}

.groupBtns{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}

.groupBtns a{
    display: block;
    width: 100%;
}

.groupBtns button {
    width: 47%;
    background: linear-gradient(180deg, #00526B 0%, #0098B9 100%);
    border-color: #00526B !important;
    font-weight: 700;
    font-size: 13px;
    padding: 13px 20px;
    border-radius: 10px;
    outline: none;
    box-shadow: none !important;
    text-transform: uppercase;
}

.about_couse h6{
    margin-bottom: 0;
    margin-top: 15px;
    font-size: 13px;
    font-weight: 700;
    text-decoration: underline;
}

.form-select{
    -webkit-appearance: none; 
    /* background-image: url('./images/ico6.svg')   */
}

.select-state-list{
    -webkit-appearance: none; 
    /* background-image: url('./images/ico6.svg'); */
    background-repeat: no-repeat;
    background-size: 17px;
    /* background-position: right; */
    background-position: calc(100% - 12px);
}

.photosBG{
    /* background-image: url('./images/textBG1.svg'); */
    background-repeat: no-repeat;
    background-size: 260px;
    background-position: center;
}

.videosBG{
    /* background-image: url('./images/textBG2.svg'); */
    background-repeat: no-repeat;
    background-size: 260px;
    background-position: center;
}

.auction_items h5 {
    font-size: 15px;
    text-align: left;
    margin: 10px 0px;
    font-weight: 600;
    color: #222;
}

.mrgT35{
    margin-top: 35px;
}

.auction_items label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #111;
    text-align: left;
}

.auction_items .form-control {
    height: auto;
    box-shadow: 0px 1px 1px #dadada !important;
    padding: 13px 15px;
    font-size: 12px;
    border: 1px solid #d4d4d4;
    outline: none;
    border-radius: 3px;
}

.upload_pic{
    border: 1px solid #e8e8e8;
    padding: 20px 0px;
    border-radius: 5px;
}

.upload_pic img{
    width: 150px;
    display: block;
    margin: auto;
}

.uploadBtn{
    background: linear-gradient(180deg, #00526B 0%, #0098B9 100%);
    border: none;
    width: 100%;
    margin-top: 17px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    padding: 12px 15px;
    outline: none !important;
    max-width: 190px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none !important;
}

.mb-4 {
    margin-bottom: 30px;
}

.upload_items {
    width: 100%;
    background: #EAFAFF;
    padding: 40px 25px;
    border-radius: 10px;
}

.upload_items h3{
    text-align: center;
    font-size: 16px;
    margin-bottom: 5px;
}

.auction_items h6{
    margin-bottom: 0;
    margin-top: 15px;
    font-size: 13px;
    font-weight: 700;
    text-decoration: underline;
}

.auction_items p {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 400;
    color: #545353;
    text-align: left;
}

.auction_items p span {
    color: #00526B;
    font-weight: 700;
    text-decoration: underline;
}

.photos_sec{
    width: 100%;
    background: none;
    padding: 0;
}

.photos_sec h6{
    margin-bottom: 0;
    margin-top: 15px;
    font-size: 13px;
    font-weight: 700;
    text-decoration: underline;
}

.photos_sec p {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 400;
    color: #545353;
    text-align: left;
}

.photos_sec p span {
    color: #00526B;
    font-weight: 700;
    text-decoration: underline;
}

.upload_text{
    width: 100%;
    background: none;
    padding: 0px;
    margin-bottom: 30px;
}

.upload_text h5 {
    text-align: left;
    font-size: 25px;
    font-weight: 800;
    font-family: 'AvenirNext', sans-serif;
    margin-bottom: 20px;
}

.upload_text h2{
    font-size: 20px;
    font-weight: 600;
    font-family: 'AvenirNext', sans-serif;
    margin-bottom: 5px;
    color: #000;
}

.upload_text ul{
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: left;
    margin-bottom: 15px;
}

.upload_text li {
    float: none;
    display: block;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}

.upload_text li span{
    font-weight: 600;
}

.upload_text h4 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 13px;
    color: #00566f;
    text-decoration: underline;
}

.upload_text .form-group{
    margin-bottom: 35px;
}

.upload_pics{
    width: 100%;
    background: none;
    position: relative;
    margin: 10px 0px;
}

.uploadBGpic{
    background: none;
    width: 100%;
    min-height: 100px;
    background-size: 220px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: auto;
    margin-right: auto;
}

.uploadBGpic img {
    max-width: 100%;
    height: 100px;
    display: block;
    margin: auto;
    object-fit: contain;
}

.upload_pics img{
    width: 100%;
    display: block;
}

.mergBtns{
    display: block;
    position: relative;
}

.mergBtns input[type="file"] {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0;
}

.block_btns {
    width: 100%;
    background: linear-gradient(180deg, #00526B 0%, #0098B9 100%);
    border-color: #00526B !important;
    font-weight: 700;
    font-size: 13px;
    padding: 20px 20px;
    border-radius: 10px;
    outline: none;
    box-shadow: none !important;
    text-transform: uppercase;
    margin-top: 20px;
}

.terms_sec{
    width: 100%;
    background: none;
}

.terms_sec ul{
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: left;
}

.terms_sec li{
    float: none;
    display: inline-block;
    margin-right: 35px;
}

.terms_sec li label{
    margin-bottom: 0px;
}

.terms_sec li [type="radio"]:checked,
.terms_sec li [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.terms_sec li [type="radio"]:checked + label,
.terms_sec li [type="radio"]:not(:checked) + label{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.terms_sec li [type="radio"]:checked + label:before,
.terms_sec li [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #006680;
    border-radius: 100%;
    background: #fff;
}
.terms_sec li [type="radio"]:checked + label:after,
.terms_sec li [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #005c75;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.terms_sec li [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.terms_sec li [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.invoice_message {
    width: 100%;
    background: none;
    padding: 30px 20px;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    box-shadow: 0px 1px 1px #c3c3c3;
}

.invoice_message ul{
    padding-left: 20px;
    list-style: disc;
}

.invoice_message li {
    display: list-item;
    font-size: 14px;
    line-height: 28px;
    color: #969393;
    font-weight: 200;
}

.invoice_message li:hover{
    text-decoration: underline;
    cursor: pointer;
}

.congratulation{
    width: 100%;
    /* background: url('./images/bg6.png'); */
    padding: 30px 0px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: 25px;
}

.congratulation h1{
    margin: 0px;
    color: #fff;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}

.steps_status {
    width: 100%;
    background: none;
    padding: 0px;
    margin-bottom: 35px;
}

.steps_status ul{
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: center;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.steps_status ul:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #c3c3c3;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 24px;
}

.steps_status li {
    float: none;
    display: inline-block;
    margin: 0px;
    width: 10%;
    text-align: center;
    position: relative;
    line-height: 19px;
}

.steps_status li span {
    font-size: 14px;
    font-weight: 400;
    line-height: 0px;
}

.steps_status li img {
    width: 48px;
    display: block;
    margin: 0px auto;
    padding: 5px;
    background: #fff;
    cursor: not-allowed;
}

.step_active img{
    cursor: pointer !important;
}

.auction_banner{
    width: 100%;
    background: none;
    padding: 30px 0px 15px;
}

.auction_banner img{
    width: 100%;
    display: block;
    margin: 0px auto;
    height: 518.40px;
    object-fit: cover;
    object-position: center;
}

.auct_preview{
    width: 100%;
    padding: 30px 0px 0px;
    background: none;
}

.auct_preview h3 {
    font-family: 'AvenirNext', sans-serif;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 25px;
}

.auct_preview p {
    font-family: 'AvenirNext', sans-serif;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 17px;
    color: #000;
}

.auct_btns{
    width: 100%;
    background: none;
    padding: 10px 0px;
}

.auct_btns button{
    background: linear-gradient(180deg, #00526B 0%, #0098B9 100%);
    border: none;
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 25px;
    border-radius: 10px;
    outline: none !important;
    box-shadow: none !important;
    margin-right: 15px;
}

.auction_tabs{
    width: 100%;
    background: none;
    padding: 25px 0px 0px;
}

.nav-tabs .nav-link button, .nav-tabs .nav-link {
    outline: none !important;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    padding: 13px 0px;
}

.tab-content{
    width: 100%;
    padding: 40px 0px 0px;
    background: none;
}

.alllots_search{
    width: 100%;
    background: none;
    padding: 0px;
    margin-bottom: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    position: relative;
}

.field_outer{
    position: relative;
    overflow: hidden;
}

.alllots_search input {
    padding: 11px 25px;
    border-radius: 50px;
    border: 2px solid #005871 !important;
    outline: none !important;
    font-weight: 300;
    box-shadow: none !important;
    color: #333;
    text-align: left;
    padding-left: 60px;
    font-size: 16px;
    height: 55px;
}

.alllots_search img.search{
    width: 21px;
    position: absolute;
    left: 24px;
    top: 17px;
}


.nav-tabs li {
    width: 33.33%;
    text-align: center;
    border: none;
}

.nav-tabs li button{
    width: 100%;
    border: 2px solid transparent;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    background: linear-gradient(180deg, #00526B 0%, #0098B9 100%);
    color: #fff;
    border-color: #00657f;
    padding: 2px;
    border-radius: 10px;
    overflow: hidden;
}

.nav-tabs li:first-child button {
    border-radius: 7px 0px 0px 7px !important;
}

.nav-tabs li:last-child button {
    border-radius: 0px 7px 7px 0px !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #fff;
    border-color: #d6d6d6 !important;
    border-radius: 0px;
    outline: none !important;
    color: #00566e !important;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: transparent;
    isolation: isolate;
}

.alllots_data{
    width: 100%;
    background: none;
    padding: 20px 0px 0px;
}

.alllots_data h6 {
    text-align: left;
    font-size: 15px;
    margin-bottom: 10px;
    color: #333;
    font-weight: 400;
}

.mrgT35{
    margin-top: 35px;
}

.alllots_card{
    width: 100%;
    background: none;
    padding: 0px;
    margin-bottom: 40px;
}

.alllots_card img {
    max-width: 100%;
    display: block;
    margin: auto;
    margin-bottom: 15px;
    width: 200px;
    height: 190px;
    object-fit: cover;
    border-radius: 10px;
}

.alllots_card h2{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 7px;
    color: #333;
}

.alllots_card p{
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 7px;
    color: #333;
}

.alllots_card h4{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    color: #333;
}

.ready_publish{
    width: 100%;
    /* background: url('./images/bg6.png'); */
    background-size: cover;
    background-position: center;
    padding: 35px 0px;
    border-radius: 10px;
}       

.ready_publish h1{
    margin: 0;
    color: #fff;
    font-size: 23px;
    font-weight: 700;
    text-align: center;
}

.ready_publish h1 button{
    outline: none;
    box-shadow: none !important;
    font-size: 15px;
    font-weight: 700;
    width: 200px;
    padding: 12px 15px;
    background: #ffff !important;
    color: #006580 !important;
    border: none !important;
    margin-left: 100px;
}

.still_need{
    width: 100%;
    padding: 35px 0px;
    background: none;
}

.still_need h1{
    margin: 0;
    color: #353535;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
}

.still_need h1 button{
    outline: none;
    box-shadow: none !important;
    font-size: 15px;
    font-weight: 700;
    width: 200px;
    padding: 12px 15px;
    background: linear-gradient(180deg, #00526B 0%, #0098B9 100%);
    border: none;
    color: #fff;
    border: none !important;
    margin-left: 100px;
}

.notfound_sec{
    width: 100%;
    background: none;
    padding: 35px 0px;
}

.notfound_sec img{
    width: 400px;
    display: block;
    margin: auto;
    margin-bottom: 40px;
}

.notfound_sec h1 {
    text-align: center;
    font-size: 40px;
    font-weight: 800;
    font-family: 'AvenirNext', sans-serif;
    margin: 0;
    margin-bottom: 20px;
}

.notfound_sec p{
    text-align: center;
    margin-bottom: 20px;
    font-size: 15px;
    max-width: 485px;
    display: block;
    margin: auto;
    font-weight: 400;
    color: #444;
}

.notfound_sec button {
    background: linear-gradient(180deg, #00526B 0%, #0098B9 100%);
    border: none;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 25px;
    border-radius: 10px;
    outline: none !important;
    box-shadow: none !important;
    min-width: 245px;
    margin-top: 30px;
}

.table_sec {
    width: 100%;
    background: none;
    padding: 30px 0px;
}

.table>:not(:first-child) {
    border-top: none;
}

.table td, .table th {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 10px;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: rgba(255, 255, 255, 0.05);
    color: var(--bs-table-striped-color);
}

.table_sec h2 {
    text-align: left;
    font-size: 22px;
    margin-bottom: 25px;
    text-decoration: underline;
}

.table_sec table{
    margin-bottom: 35px;
}

.table_sec h2 button{
    float: right;
    background: linear-gradient(180deg, #00526B 0%, #0098B9 100%);
    border-color: #00526B !important;
    font-size: 13px;
    outline: none !important;
    box-shadow: none !important;
    font-weight: 500;
}

.auction_items h3 {
    text-align: left;
    font-size: 21px;
    font-weight: 600;
    text-decoration: underline;
}

.photos_sec .form-select{
    height: auto;
    font-size: 15px;
    outline: none;
    box-shadow: none !important;
    padding: 15px 25px;
}

#tellabout h2 {
    font-size: 22px;
    margin-bottom: 20px;
    text-decoration: underline;
    font-weight: 500;
    color: #403e3e;
}

.myclass {
    width: 50%;
}

img{
    max-width: 100%;
}

.uploadItems{
    width: 100%;
    background: none;
    padding: 0px;
}

.chooseUpload {
    width: auto !important;
    font-size: 12px !important;
    padding: 5px 15px !important;
    border-radius: 5px !important;
    display: block;
    margin: auto;
    text-transform: capitalize !important;
}

.upload_item_ind{
    width: 100%;
    background: #EAFAFF;
    padding: 20px 10px;
    margin-top: 40px;
}

.upload_item_ind p{
    margin-bottom: 20px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
}

.errorUpload {
    width: 350px;
    margin-bottom: 0;
    height: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
}

.upload_text h3{
    margin-top: 20px;
    margin-bottom: 0;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
}

.upload_text h3 a{
    display: block;
    margin-top: 11px;
    font-weight: 700;
    color: #006580;
    font-size: 14px;
    text-decoration: underline !important;
}

.feature_image {
    width: 100%;
    background: none;
    padding: 0px;
    border: 1px solid #ececec;
    margin-bottom: 30px;
    padding: 15px 20px;
    border-radius: 10px;
}

.feature_image .upload_pics img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    object-fit: contain;
}

.question_info{
    width: 20px;
    height: 20px;
    display: inline-block;
    background: #000;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    font-weight: 600;
    margin-left: 7px;
    position: relative;
}

.tooltip {
    position: relative;
    display: inline;
    color: #fff;
    z-index: 9999999;
    opacity: 1;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px 5px 5px;
    position: absolute;
    z-index: 1;
    margin-top: -33px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.pics_outerbar {
    width: 100%;
    background: none;
    padding: 0px;
    position: relative;
}

.pics_outerbar textarea{
    /* background: url('./images/textBG1.svg'); */
    background-size: 270px;
    background-repeat: no-repeat;
    background-position: center;
}

.auc_view_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.auc_view_heading > button {
    outline: none;
    box-shadow: none !important;
    font-size: 15px;
    font-weight: 700;
    width: 200px;
    padding: 12px 15px;
    background: linear-gradient(180deg, #00526B 0%, #0098B9 100%);
    border: none;
    color: #fff;
    border: none !important;
}

.store_pics_outerbar1 {
    width: 100%;
    background: none;
    padding: 0px;
    position: relative;
}

.store_pics_outerbar1 textarea{
    /* background: url('./images/backText1Photos.svg'); */
    background-size: 270px;
    background-repeat: no-repeat;
    background-position: center;
}

.store_pics_outerbar4 {
    width: 100%;
    background: none;
    padding: 0px;
    position: relative;
}

.store_pics_outerbar4 textarea{
    /* background: url('./images/backText4Photos.svg'); */
    background-size: 270px;
    background-repeat: no-repeat;
    background-position: center;
}


.vedioBG{
    /* background: url('./images/textBG2.svg') !important; */
    background-size: 270px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.causeImageBG{
    /* background: url('./images/backText3Photos.svg') !important; */
    background-size: 270px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.causeVideoBG{
    /* background: url('./images/backText1Videos.svg') !important; */
    background-size: 270px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.photo_outdiv{
    display: block;
    height: auto;
    margin-right: 0px;
    padding: 10px 10px;
    text-align: center;
    margin-top: 15px;
    border: none;
    position: relative;
}

.photo_outdiv img{
    max-width: 100%;
    max-height: 150px;
}

.photo_outdiv .close {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
    background-color: red;
    padding: 5px 2px 2px;
    color: white;
    /* font-weight: bold; */
    cursor: pointer;
    opacity: .2;
    text-align: center;
    font-size: 19px;
    line-height: 10px;
    border-radius: 50%;
}
.photo_outdiv:hover .close {
    opacity: 1;
}

input.uploadImgs{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    outline: none !important;
    opacity: 0;
}

.topLogobar{
    width: 100%;
    background: none;
    padding: 15px 0px;
}

.topLogobar img{
    width: 125px;
    display: block;
    margin: 0;
}

.loginBtn{
    text-align: center;
    text-decoration: underline;
    font-size: 20px;
    margin-bottom: 30px;
    color: #007996;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}

.loginBtn a{
    color: #007996;
    text-decoration: underline;
}

.login_sec{
    min-height: calc(100vh - 71px);
}

.upload_item_ind {
    width: 100%;
    background: #EAFAFF;
    padding: 20px 10px;
    margin-top: 0;
    height: 210px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.boxBG{
    background: #EAFAFF;
    padding: 15px 0px;
    width: 100%;
}

.error-label {
    font-size: 10px;
    color: red;
    margin-left: 5px;
}

.error-field {
    border-color: red !important;
    background-color: #ff363629 !important;
}

.error_msg {
    color: #ff3636e3 !important;
}

.success_response_msg {
    color: #00b300 !important;
}

.error_response_msg {
    color: #ff3636e3 !important;
}

.image-preview {
    display: flex;
    justify-content: space-around;
}

/* Newly Added */
.login_link_wraper {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.login_link_wraper > a {
    font-size: 12px;
}
.about_cause_char_counter {
    font-size: 13px;
    margin: 10px 0;
    display: block;
    text-align: end;
}
.auc_new_img_bg {
    /* background: url(../src/images/backText1Photos.svg) !important; */
    background-size: 270px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
.auc_new_img_p_bg {
    /* background: url(../src/images/backText1Photos.svg) !important; */
    background-size: 270px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
.auc_new_vdo_bg {
    /* background: url(../src/images/backText1VideosNew.svg) !important; */
    background-size: 270px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
.event_preview_period_wraper {
    display: flex;
    align-items: center;
    gap: 20px;
}
.event_preview_period_wraper > span {
    font-size: 14px;
}

@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite; font-size: 20px; }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }

@keyframes blink {50% { color: transparent }}
.btn_loader__dot { animation: 1s blink infinite; font-size: 13px; }
.btn_loader__dot:nth-child(2) { animation-delay: 250ms }
.btn_loader__dot:nth-child(3) { animation-delay: 500ms }

@media (min-width: 576px) {
    .cause-video-container {
        flex: 0 0 auto;
        width: 100%;
    }
}