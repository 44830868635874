.faq_main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* margin-bottom: 150px; */
}
.faq_heading {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
    font-weight: 800;
}
.faq_block > h4 {
    margin: 25px 0;
    font-weight: 800;
}
.faq_block > ol {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 300;
}
.faq_mini_wraper > h5 {
    margin-bottom: 15px;
}

/* @media screen and (min-width: 768px) {
    .faq_main {
        width: 85vw;
        margin: 0 auto;
    }
} */