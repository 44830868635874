.preview-container {
    padding: 20px;
}
.preview-hero {
    display: flex;
    flex-direction: column;
}

.preview-cover-image {
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.preview-cover-image > img {
    max-width: 100%;
    object-fit: contain;
}

.preview-info {
    display: flex;
    padding: 15px 0;
    align-items: center;
    justify-content: space-between;
}

.preview-status {
    border: 1px solid black;
    font-size: 0.8rem;
    font-weight: 600;
    display: inline-block;
    padding: 3px 10px;
    border-radius: 20px;
    margin-bottom: 8px;
}

.preview-lot-head {
    font-size: 2rem;
    text-align: center;
    margin-top: 15px;
    font-weight: 600;
}

.preview-items-container {
    padding: 20px;
}

.items-cards {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
}

.preview-items-loading {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width:1200px) {
    .items-cards{
        gap:15px;
    }
}