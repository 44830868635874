.items-navtab {
    display: flex;
    border-bottom: 1px solid #d3d3d3;
}

.items-navtab-radio {
    display: flex;
    border-bottom: 2px solid black;
    align-items: center;
    padding: 20px 15px;
    gap: 10px;
    /* margin-bottom: 20px; */
}

.items-navtab-tab {
    padding: 10px 30px;
    cursor: pointer;
    position: relative;
}

.items-navtab-tab.selected {
    color: white;
    background: linear-gradient(#564A9D, #88419F);
}

.add-all-button {
    padding: 6px 10px;
    float: right;
    margin: 10px;
}