.main-container {
    flex: 1;
    /* background-color: #c1d0e3; */
    overflow-y: auto;
    padding: 30px 0;
    position: relative;
}

.main-container-title {
    position: relative;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 12px;
}

.main-box {
    width: 70vw;
    min-height: 80%;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    margin: auto;
    overflow: hidden;
}

.add_events {
    top: 0;
    position: absolute;
    /* border: 2px solid #9e55c2; */
    border: 2px solid;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 3px 7px;
    right: 7%;
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
}