.input-container {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
}

.input-label {
    font-size: 0.85rem;
    font-weight: 600;
    margin-bottom: 2px;
    position: relative;
} 

.required-asterisk {
    color: red;
    position: absolute;
    margin-bottom: 2px;
    margin-left: 2px;
}

.input-container > input {
    border-radius: 20px;
    border: 1px solid #d3d3d3;
    outline: none;
    padding: 5px 10px;
    font-size: 0.95rem;
}

.input-container > input::placeholder {
    color: #d3d3d3;
}

.input-container > input:focus {
    outline: 1px solid #22a8f6;
}

.input-box {
    position: relative;
}

.input-icon-right {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(-50%, -50%);
}