.main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: aquamarine; */
}
.main input:focus{
    outline: none;
} 


.subtitle_div{
    /* background-color: aqua; */
    margin-top: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 270px;
    justify-content: space-between ;
}

.editor_wrapper{
    width: 100%;
    margin-bottom: 40px;
    margin-top:25px;
}

.editor_input{
    height: 400px;
    width: 100%;   
}