.mainSection {
    display: flex;
    width:100%;
}

.mainContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.itemCard {
    flex-direction: column;
    width: 250px;
    align-items: center;
    user-select: none;
    cursor: pointer;
    position: relative;
}

.grabber {
    cursor: grab;
    position: absolute;
    right: 10px;
    font-size: 20px;
    font-weight: 600;
}

.selected {
    border: 2px solid var(--primary-color);
}

.dragLayer {
    position: fixed;
    pointer-events: none;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    height: 200px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}
