:root {
    --primary-color: #FC3300;
    --shady-white: #F0E8E3;
}

.cause_form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}

.address {
    display: flex;
    gap: 10px;
}

.event-needhelp > span {
    cursor: pointer;
}

.event-submit-btn{
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 25px;
    font-size: 15px;
    padding: 10px 0;
    color: white;
    font-weight: 600;
    margin: 5px 0;
    /* background-image: linear-gradient(to left,#5BA4ED,#B534AF); */
    background-color: var(--primary-color);
}