.notfound-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notfound-content {
    display: flex;
    align-items: center;
}

.notfound-content > div:nth-child(1) {
    font-size: 2.7rem;
    font-weight: 600;
}

.notfound-content > div:nth-child(2) {
    width: 1px;
    background-color: #d3d3d3;
    height: 70px;
    margin: 0 20px;
}

.notfound-content > div:nth-child(3) {
    font-size: 1.1rem;
}