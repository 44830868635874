.table_btn {
    /* border: 1px solid #d3d3d3; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    padding: 2px 9px;
    border-radius: 20px;
    /* background-color: white; */
    cursor: pointer;
    width: fit-content;
    display: flex;
}

.itemListMenuButtons {
    width: 100% !important;
    justify-content: flex-start !important;
    gap: 6px !important;
}