.csvContainer {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.alertContainer {
    min-width: 400px;
    text-align: center;
    font-weight: 600;
}

.variant_cell {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
