.asn-container{
    display: flex;
    width: 100%;
    column-gap: 5px;
}
.asn-container-left{
    width:45%;
}

.asn_header_actions_container {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: flex-end;
}

.variant-text {
    font-weight: 600
}

.filters_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 20px;
}

.filters_section {
    display: flex;
    justify-content: space-between;
}