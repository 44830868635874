.bg-modal {
    position: fixed;
    padding-left: 20%;
    inset: 0;
    background-color: rgba(0,0,0,0.08);
    backdrop-filter: blur(6px);
    z-index: 40;
    display: flex;
    justify-content: center;
    top:10%;
}

.bg-modal-content {
    background-color: white;
    width: 60%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 0;
    border-radius: 20px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.bg-modal-title-container {
    background-color: whitesmoke;
    border-bottom: 1px solid #d3d3d3;
    padding: 10px 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bg-modal-title-container > div:nth-child(2) {
    cursor: pointer;
}

.bg-modal-child {
    padding: 15px 50px;
    overflow-y: auto;
    max-height: 80%;
    flex: 1;
}

.bg-modal-submit-container {
    padding: 10px;
    width: 100%;
    border-top: 1px solid #d3d3d3;
    background-color: whitesmoke;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.bg-modal-submit-container > button {
    /* display: block; */
    margin: auto;
    /* border: none; */
    /* border-radius: 20px; */
    font-size: 15px;
    padding: 8px 40px;
    /* color: white; */
    font-weight: 600;
    /* background-image: linear-gradient(to left,#5BA4ED,#B534AF); */
}