.adv-event-container {
    display: flex;
    align-items: end;
    gap: 30px;
}

.adv-no-data {
    width: 100%;
    height: 200px;
    display: flex;
    font-size: 1.3rem;
    align-items: center;
    justify-content: center;
    color: #8b8b8b;
}
.adv-heading-container{
    display: flex;
    justify-content: space-between;
}

.actions_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}