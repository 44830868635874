.itemslist-row:nth-child(even) {
    background-color: #f3f5f7;
}

.variant_modal_container{
    width: 100%;
}

.list-action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.table_actions_cell {
    display: flex;
    align-items: center;
    gap: 14px;
}

.items-nav-container {
    display: flex;
    align-items: center;
    gap: 5px;
    border-bottom: 2px solid black;
}

.search-input {
    width: auto;
    flex: 1;
    padding-right: 15px;
}