.signin-container {
    display: flex;
    min-height: 70vh;
}

.signin-image {
    width: 40%;
    min-height: 100%;
    background-color: rgb(137, 134, 137);
}

.signin-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.signin-right {
    flex: 1;
    display: flex;
    min-height: 100%;
    padding: 30px 0;
}

.signin-form-container {
    width: 80%;
    margin: auto;
    text-align: center;
}

.signin-heading {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.signin-heading-image {
    /* width: 100px; */
    height: 70px;
    margin: auto;
    margin-bottom: 25px;
}

.signin-heading-image > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.signin-two-inputs {
    display: flex;
    gap: 20px;
}

.signin-password-container {
    text-align: right;
}

.signin-submit-container {
    margin-top: 25px;
}

.signin-submit-button button{
    width: 100%;
    height: 35px;
    box-sizing: border-box;
    background-color:#FC3300;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    padding: 0.3rem;
    color: white;
    margin: 5px 0;
}
/* .signin-submit-button button:hover{
    background-image: linear-gradient(to right,#5BA4ED,#B534AF);
} */

.signin-submit-button button:disabled{
    background-color: #d3d3d3;
    background-image: none;
}

.signin-forgot {
    font-size: 0.8rem;
    margin-top: 7px;
    text-align: right;
}

.signin-forgot > span {
    cursor: pointer;
}

.signin-password-container {
    position: relative;
}

.signin-showicon {
    position: absolute;
    top: 70%;
    right: 10px;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.signin-change-email {
    font-size: 0.8rem;
    margin-top: 7px;
    text-align: right;
    color: #FC3300;
    font-weight: 600;
    text-decoration: underline;
}

.signin-change-email > span {
    cursor: pointer;
}