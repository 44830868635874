.loading-btn {
    width: 100%;
    height: 35px;
    box-sizing: border-box;
    background-color: #FC3300;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    padding: 0.3rem;
    color: white;
    margin: 5px 0;
}