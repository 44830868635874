.container {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
}

.image_wrapper {
    cursor: pointer;
}

.section {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    justify-content: center;
    align-items: center;
}

.userImage_container {
    width: fit-content;
    margin: auto;
}