.imageinput-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: #ebebeb;
  border-radius: 20px;
}

.imageinput-image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.imageinput-video {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
}

.imageinput-image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.imageinput-file-wrapper {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
  background: white;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  border-radius: 10px;
}

.imageinput-file-wrapper > input {
  position: absolute;
  inset: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}