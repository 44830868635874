.forgot_password {
    display: flex;
    flex-direction: column;
    padding: 34px 14px;
    gap: 24px;
    align-items: center;
}
.forgot_password > img {
    height: 60px;
    margin-bottom: 94px;
}
.forgot_password > button {
    width: 50%;
    height: 35px;
    box-sizing: border-box;
    background-color:#FC3300;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    padding: 0.3rem;
    color: white;
    margin: 5px 0;
}
.back_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
}
.back_wrapper > .back {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    font-family: 'Instrument Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border-radius: 5px;
    background-color: transparent;
    border: 0.5px solid black;
}

/* SendResetLink */
.comp_wrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    width: 50%;
}
.comp_wrapper > h3 {
    font-family: 'Instrument Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 36px;
    letter-spacing: 0.035em;
    color: #000000;
    margin-bottom: 25px;
}
.wrapper_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 7px;
}
.wrapper_top > h3 {
    font-family: 'Instrument Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.035em;
    color: #000000;
    margin: 0;
}
.wrapper_top > p {
    font-family: 'Instrument Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #4A4848;
    margin: 0;
}
.comp_forgot_input {
    flex-grow: 1;
    width: 100% !important;
}
.comp_forgot_input > label {
    font-family: 'Instrument Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24px;
}

/* ResetPassword */
.comp_reset_password_input {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: center;
    width: 100%;
}