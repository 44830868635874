.navbar {
    position: fixed;
    width: 100%;
    height: 80px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background-color: white;
}

.navbar-place {
    height: 80px;
}

.navbar-logo > img {
    height: 50px;
    /* width: 400px; */
    object-fit: cover;
    cursor: pointer;
}

.navbar-menu {
    display: flex;
    align-items: center;
    gap: 30px;
}

.navbar-help {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    text-decoration: underline;
    color: #a5a5a5;
    cursor: pointer;
}

.navbar-v-line {
    width: 1px;
    height: 50px;
    background-color: #d3d3d3;
}

.navbar-buttons {
    display: flex;
    align-items: center;
    gap: 20px;
}
.navbar-buttons .signin-btn{
    width:150px;
    height: 40px;
    border: 1px solid #FC3300;
    background-color: white;
    color: #FC3300;
    border-radius: 30px;
}
.navbar-buttons .register-btn{
    width:150px;
    height: 40px;
    border: 1px solid var(--purple);
    background-color:#FC3300;
    color: white;
    border-radius: 30px;
    border: none;
}

.navbar-username {
    font-weight: 600;
    font-size: 1.1rem;
}