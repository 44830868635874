:root {
  --primary-color: #FC3300;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.description {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.descTop {
    display: flex;
    justify-content: space-between;
}

.descTop > p {
    font-size: 17px;
    font-weight: bold;
}

.descTop > p:nth-child(2) {
    color: blue;
    cursor: pointer;
}

.descTop > p:nth-child(2):hover {
    text-decoration: underline;
    color:purple;
}

.longDesc {
    font-size: 16px;
    border:1px solid;
    padding: 10px;
}

.tagContainer {
    display: flex;
    gap: 10px;
}

.tags {
    font-size: 15px;
    background-color: var(--primary-color);
    border-radius: 15px;
    padding: 10px 5px;
    color: white;
    min-width: 46px;
    text-align: center;
}

.description {
    margin-top: 10px;
}