.dropdown_container {
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 10%;
    margin: 0 5%;
    align-items: baseline;
    
}

.checkbox_container {
    display: flex;
    padding: 0 5%;
    justify-content: space-between;
    margin-bottom: 10px;
}
.checkbox_container>div {
    display: flex;
    justify-content: space-between;
}
.checkbox_inner_container {
    width: 50%;
    margin-top: 38px;
}


.card_container {
    height: 135px;
    text-align: center;
    margin: 20px 0;
    padding:15px 10px;
    display: flex;
    justify-content: space-around;
    border: 2px solid #00000026;
    border-radius: 5px;
}

.card_container>div {
    width: 25%;
    height: 100%;
    border-radius: 5px;

    background-color: #fff;
    color: rgba(0, 0, 0, 0.57);
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.card_title {
    font-weight: 600;
    font-size: 1.3rem;
    color: black;
}
.csv_container{
    display: flex;
    justify-content: end;
    padding-right:45px;
    padding-bottom: 5px ;
    
}
.card_container_title{
    position: absolute;
    margin-top: -35px;
    background-color: white;
    padding: 0 12px;
    font-size: 20px;
    font-weight: 600;
}
.oar_bid_count{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

}

.sku_col {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.orderSearchSection {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 30px;
}