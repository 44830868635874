.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    box-shadow: 0 -4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 100;
    background-color: white;
}

.footer-place {
    height: 80px;
}

.footer-logo > img {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.footer-desc {
    font-size: 0.6rem;
    color: #908f8f;
}

.footer-social {
    display: flex;
    gap: 10px;
}

.footer-social > a {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content:center;
    background-color: var(--purple);
    border-radius: 50%;
    overflow: hidden;
}