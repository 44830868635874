.formtextarea-container {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.formtextarea-label {
    font-size: 0.85rem;
    font-weight: 600;
    margin-bottom: 2px;
}

.formtextarea-textarea-container {
    width: 100%;
    border: 1px solid #d3d3d3;
    border-radius: 20px;
    padding: 5px 10px 0 5px;
}

.formtextarea-textarea-container > textarea {
    outline: none;
    border: none;
    font-size: 0.95rem;
    resize: none;
    width: 100%;
}

.formtextarea-textarea-container:focus-within {
    outline: 1px solid #22a8f6;
}

.formtextarea-max-chars {
    text-align: right;
    font-size: 0.7rem;
    margin-top: 3px;
}