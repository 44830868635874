:root {
    --primary-background-color: #FC3300;
    --white-background-color: #F0E8E3;
}

.bg-alert {
    position: fixed;
    margin-left: 20%;
    inset: 0;
    background-color: rgba(0,0,0,0.08);
    backdrop-filter: blur(6px);
    z-index: 60;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px
}

.bg-alert-content {
    padding: 20px;
    font-weight: 600;
}

.bg-alert-box {
    background-color: white;
    width: 40%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.bg-alert-btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    background-color: whitesmoke;
    padding: 10px 20px;
}

.bg-alert-ok, .bg-alert-cancel {
    border: none;
    cursor: pointer;
    /* border-radius: 2px; */
    border-radius: 25px;
    font-size: 0.9rem;
    padding: 5px 10px;
    font-weight: 600;
    color: white;
}

.bg-alert-ok {
    /* background-color: #88419F; */
    background-color: var(--primary-background-color);
    border-radius: 25px;
}

.bg-alert-cancel {
    background-color: var(--white-background-color);
    color: var(--primary-background-color);
    border: 1px solid var(--primary-background-color);
}