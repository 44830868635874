:root {
  --primary-color: #FC3300;
}
.event-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}

.event-two-inputs {
    display: flex;
    gap: 20px;
    width:100%;
}

.event-needhelp {
    text-align: center;
    font-size: 0.8rem;
    text-decoration: underline;
    font-weight: 600;
    color: var(--primary-color);
}

.event-needhelp > span {
    cursor: pointer;
}

.event-submit-btn{
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    padding: 10px 0;
    color: white;
    font-weight: 600;
    margin: 5px 0;
    /* background-image: linear-gradient(to left,#5BA4ED,#B534AF); */
    background-color: var(--primary-color);
}

.event-submit-btn:disabled{
    background-color: #d3d3d3;
    background-image: none;
}

.event-cause-drop {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.event-cause-drop > div:nth-of-type(1) {
    flex: 1;
}


.datetime-container {
    position: relative;
    display: inline-block;
  }
  
  .datetime-container button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 6px;
    margin-right: 6px;
  }


  .custom_category_image_container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

  }

  .custom_category_container{
    border: 2px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.25);
  }

  .event_browse_image_wrapper {
    display: flex;
    gap: 22px;
    width: 100%;
    margin-top: -5px;
  }

  .browse-image-input-label{
    font-size: 0.85rem;
    font-weight: 600;
  }

  .MuiTextField-root{
    outline: none;
    max-width:100%,
  }

  .MuiTextField-root:focus{
    outline: 1px solid #22a8f6;
  }

  .asterisk_color{
    color: red;
  }

.event_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 6px;
}

.event_header > button {
  width: auto !important;
  padding-inline: 15px !important;
}

.marketStoreCategoryActions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.marketStoreCategoryActions > button {
  font-size: 0.8rem;
}

.hasAuctionBuyNowContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.hasAuctionBuyNowInputs {
  display: flex;
  justify-content: space-between;
}