.donor-promo-container{
    text-align: center;
}
.donorPromo-form-container{
    width: 50%;
    margin: auto;
}


.donor-promo-btn button{
    width: 50%;
    box-sizing: border-box;
    border: none;
    border-radius: 25px;
    font-size: 15px;
    padding: 10px 0;
    color: white;
    font-weight: 600;
    margin: 25px 0;
    /* background-image: linear-gradient(to left,#5BA4ED,#B534AF); */
    background-color: var(--primary-color);
}