.sidebarlink-container {
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.sidebarlink-selected {
    position: absolute;
    inset: 0;
    /* background: linear-gradient(#564A9D, #88419F); */
    z-index: -1;
    border-radius: 20px 0 0 20px;
}

.sidebarlink-header {
    padding: 10px 20px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.sidebarlink-header-line {
    height: 1.5px;
    flex: 1;
    background-color: #d3d3d3;
    display: inline-block;
    margin-left: 10px;
}

.sidebarlink-selected > .t {
    position: absolute;
    background: #564A9D;
    top: -100%;
    width: 10%;
    height: 100%;
    right: 0;
}

.sidebarlink-selected > .t::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 30px;
    background-color: white;
}

.sidebarlink-selected > .b {
    position: absolute;
    background:  #88419F;
    height: 100%;
    top: 100%;
    right: 0;
    width: 10%;
}

.sidebarlink-selected > .b::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 30px;
    background-color: white;
}

.sidebarlink-disabled {
    color: #a8a8a8;
    cursor: not-allowed !important;
}
