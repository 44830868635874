.bid_manage_inputcover {
    border-radius: 20px;
    display: flex;
    gap: 7px;
    align-items: center;
    width: 18%;
}

.bid_manage_custom_row {
    display: flex;
    gap: 30px;
    align-items: center;
    margin: 20px 0;
}

.bid_manage_custom_container {
    width: 100%;
}