
.edit-order{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}
.needhelp{
    margin-top: 38px;
}
.needhelp > span {
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.order-update-btn{
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    padding: 10px 0;
    color: white;
    font-weight: 600;
    margin: 5px 0;
    background-image: linear-gradient(to left,#5BA4ED,#B534AF);
}