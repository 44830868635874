.verify_email {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 26px;
}
.verify_email > h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 36px;
    letter-spacing: 0.035em;
    color: #000000;
    text-align: center;
}
.success_verify {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 26px;
    align-items: center;
    justify-content: center;
}
.success_verify > h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 36px;
    letter-spacing: 0.035em;
    color: #000000;
    text-align: center;
}
.success_verify > button {
    width: 100%;
    padding: 12px 0;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    flex: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    background-color:#FC3300;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45); */
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.verify-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.verify-login-btn {
    width: 100%;
    background-color:#FC3300;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    color: white;
    font-weight: 600;
    padding: 10px 0;
}

.verify-success {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.verify-success > h3 {
    font-weight: 600;
}