:root {
    --primay-color: #FC3300;
}

.taginput-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 20px;
    gap: 5px;
    padding: 5px 10px;
    border: 1px solid #d3d3d3;
    font-size: 0.95rem;
}

.taginput-input:focus-within {
    outline: 1px solid #22a8f6;
}

.taginput-input-elem {
    flex: 1;
    border: none;
}

.taginput-input>input:focus {
    outline: transparent;
}

.taginput-tag {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    list-style: none;
    border-radius: 6px;
    background: #d3d3d3;
    color: black;
    font-weight: 600;
}

.taginput-tag-title {
    margin-top: 3px;
}

.taginput-tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    margin-left: 8px;
    color: white;
    border-radius: 50%;
    /* background: #564A9D; */
    background-color: var(--primay-color);
    cursor: pointer;
    font-size: 0.7rem;
}