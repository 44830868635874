.item-p-back-btn {
    display: inline-block;
}

.item-p-back-btn > div {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    border: none;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    padding: 8px 18px;
    font-size: 0.9rem;
    background-image: linear-gradient(to left,#5BA4ED,#B534AF);
    cursor: pointer;
}

.item-p-main {
    display: flex;
}

.item-p-container {
    flex: 1;
    padding: 20px 0;
}

.item-p-container > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 10px 0;
}

.item-p-container > div > div:nth-of-type(1) {
    font-weight: 600;
}

.item-p-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.item-p-image {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    border: 1px solid whitesmoke;
    overflow: hidden;
}

.item-p-image > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}