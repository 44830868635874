:root{
    --red: #FC3300;
    }

.add-events-form-container{
    width: 50%;
    margin: auto;
}
.donor-event-btn button{
    width: 50%;
    box-sizing: border-box;
    border: none;
    border-radius: 25px;
    font-size: 15px;
    padding: 10px 0;
    color: white;
    font-weight: 600;
    margin: 25px 0;
    /* background-image: linear-gradient(to left,#5BA4ED,#B534AF); */
    background-color: var(--primary-color);
}

.donor-event-btn button:disabled{
    margin-top: 20px;
    width:50%;
    height: 35px;
    box-sizing: border-box;
    background-color: gray;
    background-image: none;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    padding: 0.3rem;
    color: white;
    margin-bottom: 25px;
}
.no-events{
    text-align: center;
    margin-top: 25px;
    color: #B534AF;
    margin-bottom: 25px;
}
.highlightOption{
    background-color: #67358B;
}
.option {
    font-size: 14px;
}
.chip{
    background-color: var(--red);
}

.multiSelectContainer li:hover {
    background: #d3d3d3;
    color: black;
    cursor: pointer;
  }