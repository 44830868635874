.back-btn-container{
    width:10%;
    margin-left: auto;
}
.upload-btn-container{
    width: 50%;
    margin: auto;
}
.copy_heading{
    margin-top:25px ;
    font-weight: 600;
}
.copy_url {
    height: 52px;
    display: grid;
    grid-template-columns: 96% 10%;
    align-items: center;
    border: 1px solid #767676;
    margin-top: 20px;

}

.copy_url>input {

    align-items: center;
    border: none;
    outline: none;
}

.copy_url>div {
    border: none;
    cursor: pointer;
}

.copy_url>input:focus {
    border: none;
    outline: none;
}