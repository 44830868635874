.qrMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.actionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.noDataText {
    width: 100%;
    text-align: center;
    margin-top: 50px;
}

.actionsContainer {
    display: flex;
    gap: 20px;
    align-items: center;
}

.page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    page-break-after: always;
    margin-bottom: 20px;
    padding: 30px 10px;
    /* border: 1px solid #ccc; */
    column-gap: 20px;
    row-gap: 40px;
    height: 297mm; /* A4 height */
    width: 210mm;/* A4 width */
    overflow: hidden;
}

.qrCard {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.itemDetails {
    display: flex;
    flex-direction: column;
}

.itemDetails>h4 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.itemDetails>P {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
}

@media print {
    body {
        margin: 0;
        padding: 0;
      }

    .page {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        page-break-after: always;
        margin-bottom: 20px;
        padding: 30px 10px;
        /* border: 1px solid #ccc; */
        column-gap: 20px;
        row-gap: 40px;
        height: 297mm; /* A4 height */
        width: 210mm;/* A4 width */
        overflow: hidden;
    }

    .page:last-child {
        page-break-after: auto;
   }

    .qrCard {
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .itemDetails {
        display: flex;
        flex-direction: column;
    }

    /* .itemDetails>h4 {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: black;
    }

    .itemDetails>P {
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        color: black;
    } */
}