.manage-donor-section{
    padding: 25px;
    height: 100px;
    text-align: center;
    align-items: center;
    background-color: #E7E7E7;
}
.addDonor-button{
    margin-right: 25px;
    margin-top: 2%;
    margin-bottom: 10px;
    text-align: right;
}
.addDonor-button button{
    height: 35px;
    width:100px;
    box-sizing: border-box;
    /* background-image: linear-gradient(to left,#5BA4ED,#B534AF); */
    /* border: none; */
    /* border-radius: 20px; */
    font-size: 15px;
    padding: 0.3rem;
    /* color: white; */
}
.donor-list-container{
    margin-left: 25px;
    display: flex;
    justify-content: center;
}
.manage-donor-title{
    display: flex;
    column-gap: 10px;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 0.2px solid rgb(216, 214, 214);
}


.donor-list-container tr:nth-child(2n){
    background-color: #f3f5f7;
}

.page-no{
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    margin-top: 30px;
    height: 30px;
    /* border: 1px solid blue;
    background-color: #B2DFFF; */
}
.page-no button{
    font-size: 20px;
    cursor: pointer;
    margin-right: 20px;
    height: 100%;
    width:50px;
    background-color:white ;
    outline: none;
    border:none;

}
.search-box-container{
    display: flex;
    justify-content: space-between;
}