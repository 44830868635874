.items-drag-card {
    border: 1px solid #d3d3d3;
    display: flex;
    gap: 20px;
    border-radius: 10px;
    margin: 20px 0;
    cursor: grab;
    padding: 10px 20px;
    background-color: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.items-drag-card-img {
    width: 100px;
}

.items-drag-card-img > img {
    width: 100%;
}

.items-drag-card-header {
    font-size: 1.1rem;
    font-weight: 600;
}

.items-drag-card-type {
    font-size: 0.8rem;
    color: #717171;
}

.no_items {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    width: 100%;
}