.editor_style {
    height: 131px;
    max-height: 131px;
    border: 1px solid #d3d3d3;
    border-radius: 20px;
    padding: 8px 10px 0 10px;
}

.editor_style>.ql-container.ql-snow {
    border: none;
}

.editor_style>.ql-container>.ql-editor {
    padding: 0;
    font-size: 0.95rem;
}

.editor_style>.ql-container>.ql-editor.ql-blank::before {
    font-style: normal;
    font-size: 0.95rem;
    left: 0;
    right: 0;
    font-family: 'AvenirNext', sans-serif !important;
}