.event_header_container {
    display: flex;
    justify-content: space-between;
}
.add_event{
    border: 2px solid #9e55c2;
    border-radius: 40px;
    margin: auto 10px;
    display: flex;
    width: 180px;
    height: 40px;
   
}
.add_event:active{
    transform: scale(98%);
}
.add_event>p,.add_event>span{
    margin: auto 5px;
}


.event_list_container::-webkit-scrollbar {
    display: none;
}

.calender_icon_container {
    display: flex;
    padding-top: 8px;
    padding-left: 5px;

}

.calender_icon_container>p {
    margin-bottom: 0;
}

.event_list_container>hr {
    margin: 0;
}

.striped_table_row {
    background-color: #f3f5f7;
}

.table-btn {
    border: 1px solid #d3d3d3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    padding: 2px 9px;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    width: fit-content;
    display: flex;
}