:root {
    --primary-color: #FC3300;
    --shady-white: #F0E8E3;
}

input[type=checkbox] {
    accent-color: var(--primary-color);
}

.form-top-actions {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
    padding-right: 30px;
}

.aitem-wrapper {
    position: relative;
}

.aitem-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    padding: 20px;
}

.aitem-section-head {
    font-size: 0.95rem;
    color: #656565;
}

.aitem-section-head > hr {
    margin: 0;
}

.aitem-two-inputs {
    display: flex;
    gap: 20px;
}

.aitem-two-inputs > div {
    flex: 1;
}

.aitem-submit-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    gap: 10px;
}

.aitem-submit-container > button {
    display: block;
    margin: auto;
    border: none;
    border-radius: 25px;
    font-size: 15px;
    padding: 10px 40px;
    color: white;
    font-weight: 600;
    /* background-image: linear-gradient(to left,#5BA4ED,#B534AF); */
    background-color: var(--primary-color);
}

.aitem-modal-btn {
    border: none;
    border-radius: 25px;
    color: white;
    font-weight: 600;
    padding: 8px 18px;
    font-size: 0.9rem;
    /* background-image: linear-gradient(to left,#5BA4ED,#B534AF); */
    background-color: var(--primary-color);
}

.aitem-white-button {
    color: var(--primary-color);
    background-color: transparent;
    border: 2px solid var(--primary-color);
}

.aitem-modal-btn:disabled {
    background-color: #d3d3d3;
    background-image: none;
}

.aitem-file-btn {
    margin-top: 10px;
    border: 1px solid #d3d3d3;
    width: 100%;
    border-radius: 20px;
    color: black;
    font-weight: 600;
    font-size: 0.9rem;
}

.aitem-file-btn::file-selector-button {
    /* background-image: linear-gradient(to left,#5BA4ED,#B534AF); */
    background-color: var(--primary-color);
    outline: none;
    border: none;
    /* color: white; */
    color: var(--shady-white);
    padding: 8px 10px;
}

.item-dates{
    display: flex;
    gap: 20px;
    width:100%;
}

.remove-cause-conatiner{
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
}

.remove-cause-conatiner > button{
    border: none;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    padding: 8px 18px;
    font-size: 0.9rem;
    background-image: linear-gradient(to left,#5BA4ED,#B534AF);
}

/* the below style is to prevent squize of item create layout, if the length of category name is more than the actual in multiselect dropdown */
.chip, .singleChip {
    white-space: pre-wrap !important;
}

.clear_button_container {
    /* display: flex;
    justify-content: flex-end; */
    /* padding-right: 30px; */
    /* padding-top: 20px; */
}