.variants-option-add-btn {
    border: none;
    background: none;
    color: #00526B;
    font-size: 0.9rem;
}

.variants-option-add-btn:hover {
    color: #00526Ba5;
}

.varaints-table-container > table {
    min-width: 400px;
}

.varaints-table-container tbody {
    border-top: 1px solid black !important;
}

.varaints-table-container td,
.varaints-table-container th {
    padding: 5px;
}

.varaints-table-container th {
    font-weight: 600;
}

.variants_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.options-final-buttons {
    display: flex;
    justify-content: space-between;
    text-align: right;
}