.change-email-password-container{
    position: relative;
}


.change-email-showicon{
    position: absolute;
    top: 70%;
    right: 10px;
    transform: translate(-50%, -50%);
    cursor: pointer;
}