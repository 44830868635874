.sidebar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 1px;
	background-color: #ffffff;
}

.sidebar::-webkit-scrollbar
{
	width: 8px;
	background-color: #ffffff;
}

.sidebar::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #cacaca;
}

.sidebar {
    position: fixed;
    top: 80px;
    height: calc(100vh - 80px);
    width: 280px;
    text-align: left;
    z-index: 50;
    background-color: white;
    font-size: 0.8rem;
    padding: 20px 0;
    padding-left: 5px;
    padding-bottom: 80px;
    font-weight: 600;
    overflow-y: auto;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.sidebar-place {
    width: 280px;
    text-align: left;
    background-color: white;
    font-size: 0.8rem;
    padding: 20px 0;
    padding-left: 20px;
    font-weight: 600;
    overflow-y: auto;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.sidebar-sub-menu {
    margin-left: 20px;
}

.sidebar-sub-menu > div {
    padding: 10px;
    cursor: pointer;
    position: relative;
}