.inputContainer {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #d3d3d3;
    display: flex;
    gap: 5px;
    align-items: center;
}

.searchInputBox {
    width: 100%;
    margin: 0;
}

.searchInputBox > input {
    border: none;
}

.searchInputBox > input:focus {
    outline: none;
}

.searchActions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-right: 5px;
}

.icons {
    color: #d3d3d3;
    cursor: pointer;
}